@import '../partials';

.page-not-found {
  @include content-container;
  text-align: center;

  &__boundary {
    @include interior-content-full;
  }
}
