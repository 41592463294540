//Theme Colors

// Base Colors
$white: #ffffff;
$black: #000000;
$almost-black: #231f20;
$green: #B6D840;
$green-dark: #70B90B;
$gunmetal: #46545E;
$light-grey-blue: #B1BBB9;
$sand: #E6E4DE;
$pink: #ca316f;
$red: #d84d58;
$pink-light: lighten(saturate(adjust-hue($pink, -3), 3.80), 6.27);

// Breakpoints
$x-large: 1600px;
$large: 1280px;
$medium2: 1060px;
$medium: 900px;
$medium-max: 899px;
$footer-show: 800px;
$small: 768px;
$x-small2: 650px;
$x-small3: 450px;
$x-small: 420px;
$xx-small: 375px;

// Durations
$fast-duration: .25s !default;
$slow-duration: .4s !default;

// Easing
$cubic-ease: cubic-bezier(.215,.61,.355,1);

// Common Dimensions
$component-spacing: rem(50);
$container-width: rem(1255);
$interior-content-width: rem(784);
$interior-gutter: rem(378);
$interior-padding: rem(118);

// Common Padding
$desktop-padding: rem(30);
$mobile-padding: rem(20);
$desktop-vertical-padding: rem(45);

// Styles
$gradient-black-white: linear-gradient(to bottom, $black, $white);
$drop-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);

// Fonts
$sans-serif-font: 'Arquitecta', Arial, sans-serif;
$sans-serif-body-font: 'Open Sans', Arial, sans-serif;

// Font Weights
$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-book: 600;
$font-weight-bold: 800;
$font-weight-heavy: 900;



