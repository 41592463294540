@mixin content-title {
  font-size: rem(40);
  text-transform: uppercase;

  @include bp(md) {
    font-size: rem(56);
  }

  @include bp(lg) {
    font-size: rem(72);
  }
}

@mixin quote-text {
  font-size: rem(26);
  line-height: rem(36);
  font-weight: $font-weight-normal;
  font-family: $sans-serif-font;
}

@mixin content-body {
  margin-top: 0;
  margin-bottom: 1em;
  font-family: $sans-serif-body-font;
  font-size: rem(14);
  line-height: 2.07;
}

@mixin inline-link {
  color: $pink-light;
  text-decoration: underline;
  transition: color $fast-duration;
}

@mixin intro-paragraph {
  font-size: rem(18);
  line-height: 1.78;

  @include bp(md) {
    font-size: rem(22);
    line-height: 1.64;
  }
}

@mixin body-header-base {
  font-weight: $font-weight-normal;
  text-transform: uppercase;

  strong {
    font-weight: $font-weight-bold;
  }
}

@mixin primary-body-header {
  font-size: rem(24);
  line-height: 1;
  margin: rem(12) 0;

  @include bp(md) {
    font-size: rem(32);
    line-height: 1.13;
  }
}

@mixin secondary-body-header {
  font-size: rem(18);
  line-height: 1;
  margin: rem(5) 0;

  @include bp(md) {
    font-size: rem(22);
    line-height: 1.32;
  }
}