@mixin component() {
  margin: $component-spacing auto;
}

@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin fix-long-urls(){
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin section-padding {
  padding-top: rem(60);
  padding-bottom: rem(60);
  padding-right: rem(20);
  padding-left: rem(20);
}

@mixin content-container {
  max-width: $container-width;
  margin: 0 auto;
}

@mixin interior-content-container {
  max-width: $interior-content-width;
  padding: 0 $mobile-padding;

  @include bp(lg) {
    max-width: 100%;
    width: calc(100% - #{$interior-gutter});
    padding: 0 $component-spacing;
  }
}

@mixin interior-content-full {
  max-width: $interior-content-width;
  padding: $component-spacing $mobile-padding;

  @include bp(lg) {
    max-width: 100%;
    width: calc(100%);
    padding: $component-spacing;
  }
}

@mixin ratio-box($width, $height){
  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: $height / $width * 100%;
  }
  &::after { /* to clear float */
    content: "";
    display: table;
    clear: both;
  }
}

@mixin screen-reader-only() {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

@mixin box-button($color: $green){
  color: $color;
  border: 2px solid $color;
  padding: rem(8) rem(24) rem(6) rem(24);
  font-size: rem(14);
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

@mixin text-link-arrow-right($color:'black'){
  text-transform: uppercase;
  font-size: rem(16);
  font-family: $sans-serif-font;
  text-decoration: none;
  font-weight: $font-weight-heavy;
  display: flex;
  align-items: center;
  color: $black;

  @if $color == "white" {
    color: $white;
  }

  @include bp(md) {
    font-size: rem(18);
  }

  &::after {
    width: rem(16);
    height: rem(16);
    display: inline-block;
    content: " ";
    background-image: url('../DocumentDefault/Assets/chevron-right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 0.7rem;

    @if $color == "white" {
      background-image: url('../DocumentDefault/Assets/chevron-right-white.svg');
    }
  }
}

@mixin text-link-arrow-left($color:'black'){
  &::before {
    width: rem(15);
    height: rem(24);
    display: inline-block;
    content: " ";
    background-image: url('../DocumentDefault/Assets/chevron-left.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0.7rem;

    @if $color == "white" {
      background-image: url('../DocumentDefault/Assets/chevron-left-white.svg');
    }
  }
}

@mixin overlay-gradient() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.8+0,0.3+50 */
  background: -moz-linear-gradient(top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.3) 50%,rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.3) 50%,rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin overlay-gradient-slight() {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.7) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.7) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(top, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin overlay-gradient-slight-tall() {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin overlay-gradient-strong() {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 70%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 70%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(top, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}


@mixin overlay-gradient-strong-tall() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.3+40,1+75 */
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 54%, rgba(0,0,0,1) 89%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 54%,rgba(0,0,0,1) 89%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 54%,rgba(0,0,0,1) 89%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin dropdown-filter() {
  display: flex;
  align-items: center;
  padding: 0 rem(60) 0 rem(16);
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  border-radius: 0;
  background-color: #fff;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #232220;
  font-size: rem(14);
  font-family: $sans-serif-body-font;
  line-height: 2;
  max-width: rem(400);
  width: 100%;
  height: rem(60);
  background-image: url('../DocumentDefault/Assets/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 24px) center;
}

@mixin filter-apply-button(){
  background-color: $white;
  text-transform: uppercase;
  text-align: center;
  font-size: rem(14);
  font-weight: $font-weight-heavy;
  border: 1px solid $black;
  height: rem(60);
  min-width: rem(130);
  padding: rem(16);
  cursor: pointer;
}

@mixin filter-checkbox {
  text-transform: uppercase;
  font-size: rem(14);
  font-weight: $font-weight-heavy;
  background: url('../DocumentDefault/Assets/checkbox-empty.svg');
  background-repeat: no-repeat;
  background-size: rem(20) rem(20);
  min-height: rem(20);
  display: flex;
  align-items: center;
  padding-left: rem(36);

  &.checked {
    background: url('../DocumentDefault/Assets/checkbox-checked.svg');
    background-repeat: no-repeat;
    background-size: rem(20) rem(20);
  }
}

@mixin filter-form-button(){
  background-color: $white;
  text-transform: uppercase;
  font-size: rem(14);
  font-family: $sans-serif-body-font;
  font-weight: $font-weight-heavy;
  text-decoration: underline;
  border: none;
  margin-top: rem(20);
  padding: 0;
  cursor: pointer;

  @include bp(xs) {
    margin-top: 0;
  }
}

@mixin search-filter(){
  display: block;
  padding: rem(21) rem(80) rem(20) rem(16);
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  border-radius: 0;
  background-color: $white;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #232220;
  font-size: rem(14);
  line-height: 1;
  max-width: rem(400);
  width: 100%;
}

@mixin text-input(){
  display: block;
  padding: rem(21) rem(16) rem(20) rem(16);
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  border-radius: 0;
  background-color: $white;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #232220;
  font-size: rem(14);
  line-height: 1;
  min-height: rem(40);
  max-width: rem(400);
  width: 100%;
}